
.banner-image-outer{
    width: 100% !important;
    height: 100% !important;
}
.mainBannerImage {
    display: flex;
    width: 98% ;
    margin: auto;
    height: 100% ;
    border-radius: 20px;
    cursor: pointer;
    
 
}

.casino-select-container {
    margin-top: 80px;
    margin-inline-start: 15px;
    margin-inline-end: 15px;
}

.casino-select-text-container {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.casino-select-image-container {
    margin-bottom: 30px;
}

.casino-select-text {
    font-size: 20px;
    color: #000;
    font-weight: 700;
    /* font-family: "Roboto"; */
}

@media screen and (max-width:768px) {
    .mainBannerImage {
        display: flex;
        width: 100%;
        height: 195px;
        border-radius: 0px;
        cursor: pointer;
    }
    
}